<template>
  <a-modal title="添加" :width="800" :visible="visible" @ok="handleSubmit" @cancel="handleCancel">
    <a-row :gutter="24" class="">
      <a-col :md="12" :sm="24" class="left-box">
        <a-tabs default-active-key="1" @change="callback" class="tabs-tree">
          <a-tab-pane key="1" tab="组织架构"> </a-tab-pane>
          <a-tab-pane key="2" tab="标签" force-render> </a-tab-pane>
        </a-tabs>

        <a-card :bordered="false" :loading="treeLoading">
          <div v-if="this.orgTree != ''">
            <a-tree :treeData="orgTree" v-if="orgTree.length" :replaceFields="replaceFields">
              <template slot="custom" slot-scope="item">
                <div v-if="item.userId" @click="select_user(item)" class="flex0">
                  <span
                    class="ic-radio"
                    v-if="ck_index != item.userId && userId_arr.indexOf(item.userId) === -1"
                  ></span>
                  <span class="ic-radio ic-radio-cked" v-if="userId_arr.indexOf(item.userId) !== -1"></span>
                  <span class="ic-radio ic-radio-ck" v-if="ck_index == item.userId"></span>
                  <OpenData type="userName" :openid="item.userId"></OpenData>
                </div>
                <div v-if="!item.userId" class="flex0">
                  <a-icon type="folder" theme="twoTone" class="blue-ic" />
                  <OpenData type="departmentName" :openid="item.id"></OpenData>
                </div>
              </template>
            </a-tree>
          </div>
          <div v-else>
            <a-empty :image="simpleImage" />
          </div>
        </a-card>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-card :bordered="false">
          <div class="yxz">已选择-{{ selectedRows_new.length + selectedRows.length }}</div>
          <div v-for="(o, i) in selectedRows" :key="i" class="flex user-list">
            <OpenData type="userName" :openid="o.userId"></OpenData>
          </div>
          <div v-for="(o, i) in selectedRows_new" :key="i + 'new'" class="flex user-list">
            <OpenData type="userName" :openid="o.userId"></OpenData>
            <a-button type="link" icon="close-circle" class="close-circle-bt" @click="del_user" />
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import OpenData from '@/views/fileStream/documentManage/weixin/openData'

import { STable, XCard } from '@/components'
import { Empty } from 'ant-design-vue'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { wx_user } from '@/api/modular/fileStream/user'
export default {
  components: {
    STable,
    XCard,
    OpenData,
  },
  data() {
    return {
      visible: false,
      sp_index: null,
      value: null,
      orgTree: [],
      selectedRows: [],
      selectedRows_new: [],
      sexDictTypeDropDown: [],
      statusDictTypeDropDown: [],
      treeLoading: true,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      replaceFields: {
        children: 'mychildren',
      },
      ck_index: null,
      user_list_name: null,
      userId_arr: [],
    }
  },
  created() {
    this.getTree()
    this.sysDictTypeDropDown()
  },
  methods: {
    getTree() {
      /**
       * 获取到机构树，展开顶级下树节点，考虑到后期数据量变大，不建议全部展开
       */
      wx_user()
        .then((res) => {
          this.treeLoading = false
          if (!res.success) {
            return
          }
          //
          const mydata = Object.assign([], res.data)
          async function make_mychildren(data) {
            data.forEach((element) => {
              //
              element.mychildren = element.children.concat(element.wxCorpUserList)
              //
              if (element.children && element.children.length > 0) {
                make_mychildren(element.children)
              } else {
                return
              }
            })
            return data
          }
          async function make_slot(data) {
            data.forEach((element) => {
              element.scopedSlots = { title: 'custom' }
              if (element.mychildren && element.mychildren.length > 0) {
                make_slot(element.mychildren)
              } else {
                return
              }
            })
            return data
          }
          //
          make_mychildren(mydata)
            .then((x) => {
              return make_slot(x)
            })
            .then((x) => {
              console.log(x)
              this.orgTree = x
            })
        })
        .then((x) => {
          init_wx()
        })
    },
    add(list, i) {
      console.log(list, i)
      this.ck_index = null
      this.sp_index = i
      this.selectedRows_new = []
      this.userId_arr = []
      //
      if (i === undefined) {
        this.user_list_name = list
        this.selectedRows = this.$parent[list]
      } else if (typeof i === 'number') {
        this.user_list_name = list
        this.selectedRows = this.$parent[list][i].val
      }
      //
      this.selectedRows.map((x) => {
        this.userId_arr.push(x.userId)
      })
      console.log(this.selectedRows, this.userId_arr)
      //
      this.visible = true
    },
    sexFilter(sex) {
      const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
      if (values.length > 0) {
        return values[0].value
      }
    },
    statusFilter(status) {
      const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({ code: 'sex' }).then((res) => {
        this.sexDictTypeDropDown = res.data
      })
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
    },

    handleSubmit() {
      const { sp_index, selectedRows, selectedRows_new, user_list_name } = this
      const length = selectedRows.length + selectedRows_new.length
      if (length < 1) {
        this.$message.error('请选择！')
        return
      } else {
        const rows = [].concat(selectedRows).concat(selectedRows_new)
        if (typeof sp_index === 'number') {
          this.$parent[user_list_name][sp_index].val = rows
        } else {
          this.$parent[user_list_name] = rows
        }
        console.log(rows)
        this.visible = false
      }
    },
    handleCancel() {
      this.visible = false
    },
    select_user(item) {
      if (this.userId_arr.indexOf(item.userId) !== -1) {
        return
      }
      this.ck_index = item.userId
      this.selectedRows_new = [item]
    },
    del_user() {
      this.selectedRows_new = []
      this.ck_index = null
    },
    callback(key) {
      console.log(key)
    },
  },
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex0 {
  display: flex;
  align-items: center;
}
.blue-ic {
  font-size: 1.5em;
  margin-right: 10px;
}
.ic-radio {
  display: inline-block;
  width: 17px;
  height: 17px;
  border: 1px solid #aaa;
  border-radius: 50%;
  margin-right: 10px;
}
.ic-radio-ck {
  border: 5px solid #40a9ff;
}
.ic-radio-cked {
  border: 5px solid #aaa;
}
.user-list {
  margin-bottom: 10px;
}
.yxz {
  margin-bottom: 20px;
  font-weight: bold;
}
.close-circle-bt {
  color: #aaa;
  font-size: 1.3em;
}
::v-deep .ant-modal-body {
  padding: 0;
}
.left-box {
  border-right: 1px solid #e8e8e8;
  height: 600px;
}

.left-box {
  max-height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }
}
::v-deep .ant-tabs-bar {
  margin: 0;
}
::v-deep .ant-tabs-nav-scroll {
  display: flex;
  justify-content: space-around;
}
</style>
